import styled from 'styled-components';
import Screen from '@/styles/Themes/Screen';
import { ArrowLeftCard } from '@/shared/images/icons/ArrowLeftCard';

export const RightArrowBtn = styled.div`
    position: absolute;
    height: 36px;
    width: 36px;
    right: 5px;
    top: 50%;
    cursor: pointer;
    transition: 0.3s ease 0s;
    transform: translateY(-50%);
    z-index: 5;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
    ${Screen.desktop} {
        opacity: 0;
        &:hover {
            opacity: 1 !important;
        }
    }
`;

export const LeftArrowBtn = styled.div`
    position: absolute;
    height: 36px;
    width: 36px;
    left: 5px;
    top: 50%;
    cursor: pointer;
    transition: 0.3s ease 0s;
    transform: translateY(-50%);
    z-index: 5;

    ${Screen.ipad} {
        height: 36px;
        width: 36px;
    }
    ${Screen.desktop} {
        opacity: 0;
        &:hover {
            opacity: 1 !important;
        }
    }
`;

export const RightArrowIcon = styled(ArrowLeftCard)`
    transform: rotate(180deg);
`;
export const LeftArrowIcon = styled(ArrowLeftCard)``;

export const Box = styled.div<{ withPaddings?: boolean }>`
    flex: 1;
    position: relative;
    min-height: 100px;
    max-width: 100%;
    margin: ${({ withPaddings }) => (withPaddings ? '12px 12px 0' : '0')};
    &:hover ${RightArrowBtn} {
        opacity: 0.8;
    }
    &:hover ${LeftArrowBtn} {
        opacity: 0.8;
    }
    z-index: 1;
`;

export const ImageContainer = styled.div`
    position: relative;
    min-height: 100px;
    border-radius: 15px;
    overflow: hidden;
    height: 100%;
    z-index: 4;
    img {
        position: relative;
        z-index: 3;
    }
`;

export const GalleryWrapper = styled.div<{ transform: string; transition: string; page: number }>`
    transform: ${(props) => props.transform};
    transition: ${(props) => props.transition};
    width: auto;
    display: flex;
    user-select: none;
    position: relative;
    height: 100%;
    ${Screen.mobile} {
        transform: ${(props) => `translateX(calc(-${props.page * 100}%))`};
    }
`;
