import styled from 'styled-components';
import Text from '@/styles/Themes/Text';
import Screen from '@/styles/Themes/Screen';
import Palette from '@/styles/Themes/Palette';

export const Container = styled.div<{ fullWidth?: boolean }>`
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 6px 16px 6px 24px;
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background-color: ${Palette.sand.normal};
    border-radius: 38px 0 15px 0;
    z-index: 4;
    ${({ fullWidth }) => !fullWidth && 'max-width: 74%;'}

    ${Screen.ipad} {
        ${({ fullWidth }) => !fullWidth && 'max-width: 76%;'}
    }
`;

export const Msg = styled.div`
    display: flex;
    flex-wrap: wrap;
    color: ${Palette.sand.darker};
    ${Text.Full.normal.medium}
`;
