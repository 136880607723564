import { ReactElement } from 'react';
import * as S from './BookedCount.styles';
import { FlameIcon } from '@/shared/images/icons/Flame';
import { TicketYellow } from '@/shared/images/icons/TicketYellow';
import { numberWithCommaFormatter } from '@/common/app/utils/numberUtils';

const MIN_TOTAL = 10;
const MIN_RECENT = 2;

type BookedCountProps = {
    totalPurchased?: string;
    recentPurchased?: string;
    fullWidth?: boolean;
    isFire?: boolean;
};

export const BookedCount = ({
    totalPurchased,
    recentPurchased,
    fullWidth,
    isFire,
}: BookedCountProps): ReactElement => {
    const recentNum = recentPurchased ? +recentPurchased : 0;

    const total =
        totalPurchased && +totalPurchased >= MIN_TOTAL
            ? `${numberWithCommaFormatter(+totalPurchased)} total bookings`
            : '';
    const recent =
        recentPurchased && +recentPurchased >= MIN_RECENT
            ? ` ${recentNum} times in last 48 hrs`
            : '';

    if (!total && !recent) {
        return <></>;
    }

    const text = recent && total ? `${total} - ${recent}` : (recent && `Booked ${recent}`) || total;

    return (
        <S.Container fullWidth={fullWidth}>
            {isFire ? <FlameIcon /> : <TicketYellow />}
            <S.Msg>{text}</S.Msg>
        </S.Container>
    );
};
