export const FlameIcon = () => {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.03848 21.1914C11.5119 21.3323 14.7235 19.2404 15.8986 15.9688C16.1472 15.2768 16.3114 14.5381 16.3428 13.7655C16.5181 9.44342 13.121 5.81241 13.121 5.81241C13.121 5.81241 13.3075 7.2341 13.1071 8.4297C12.9067 9.6253 12.3178 10.3404 12.3178 10.3404C12.3178 10.3404 12.8025 8.46993 12.3116 7.18278C11.7465 5.7013 10.6097 4.97858 10.2156 3.64932C9.76213 2.11943 10.6046 0.395795 10.6046 0.395795C10.6046 0.395795 8.02799 1.27315 6.09222 3.72839C3.89677 6.51301 5.14783 10.266 5.14783 10.266C5.14783 10.266 4.99547 9.23724 3.9783 8.36746C2.96113 7.49768 3.43882 6.23208 3.43882 6.23208C3.43882 6.23208 0.469456 9.30006 0.314697 13.1155C0.272684 14.1512 0.526928 15.2079 0.919581 16.1977C2.08065 19.1242 4.89258 21.0638 8.03848 21.1914Z"
                fill="#DD6A00"
            />
            <g filter="url(#filter0_d_310_217323)">
                <path
                    d="M9.92853 10.9486C10.74 11.5394 12.0377 12.4551 12.3141 14.1566C12.4591 15.0488 12.2008 15.8049 11.8063 16.4095C10.8855 17.8209 9.02023 18.7705 7.60308 17.8579C7.02 17.4823 6.46816 16.8521 6.20088 16.0417C5.93359 15.2313 5.85667 14.0078 6.09004 12.7382C6.43738 10.8486 8.47217 8.43363 8.47217 8.43363C8.47217 8.43363 8.48359 9.89626 9.92853 10.9486Z"
                    fill="#FFE600"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_310_217323"
                    x="4.2623"
                    y="7.14211"
                    width="9.78106"
                    height="13.1614"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.397381" />
                    <feGaussianBlur stdDeviation="0.844434" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.625 0 0 0 0 0.450644 0 0 0 0 0 0 0 0 1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_310_217323"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_310_217323"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
