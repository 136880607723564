export const HeartIcon = (props: { className?: string }) => {
    return (
        <svg
            className={`HeartIcon ${props.className}`}
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.7381 24.1295C12.5212 24.1295 12.4127 24.0215 12.1958 23.9135C11.2199 23.1572 3.19518 16.4589 1.78544 13.542C-0.166515 9.2205 0.267252 5.11511 3.19518 2.52224C5.36401 0.469544 9.91857 0.145435 12.6296 2.63027C12.7381 2.73831 12.8465 2.73831 12.9549 2.63027C15.666 0.145435 20.2205 0.469544 22.3894 2.52224C25.3173 5.22315 25.8595 9.43657 23.6907 13.65C21.9556 17.1072 13.6056 23.6974 13.2803 24.0215C13.0634 24.0215 12.9549 24.1295 12.7381 24.1295ZM7.74973 2.4142C6.33999 2.4142 5.03869 2.84635 4.2796 3.6026C1.89388 5.76333 1.56855 9.11246 3.30362 12.7857C4.2796 14.8384 10.027 19.9161 12.7381 22.1849C14.5816 20.6724 20.8712 15.4866 22.1725 12.7857C24.016 9.2205 23.6907 5.87137 21.1965 3.71064C19.7868 2.4142 16.2082 1.76598 13.9309 3.81868C13.2803 4.46689 12.0874 4.46689 11.4368 3.81868C10.4608 2.84635 9.05103 2.4142 7.74973 2.4142Z"
                fill="#EC465A"
            />
        </svg>
    );
};
